import utility from '@/common/utility'
import auth from '@/common/auth'

export default class RoleModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.roleGuid
      this.roleGuid = props.roleGuid
      this.roleName = props.roleName
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.authoritiesGuidList = props.authoritiesGuidList
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.roleGuid) this.roleGuid = ''
    if (!this.roleName) this.roleName = ''
    if (!this.staffName) this.staffName = ''
    if (!this.authoritiesGuidList) this.authoritiesGuidList = []
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.roleGuid = utility.getUuid()
    this.resourceId = this.roleGuid
  }
}
