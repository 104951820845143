<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="roleModel"
    label-width="70px"
    >
    <el-form-item label="名称：" prop="roleName">
      <el-input
        type="text"
        v-model="roleModel.roleName">
      </el-input>
    </el-form-item>
    <el-form-item label="权限：" prop="authoritiesGuidList">
      <my-tree
        v-model="roleModel.authoritiesGuidList"
        :nodes="authorities"
        node-key="authorityId"
        :props="props"
        >
      </my-tree>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="roleModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import RoleModel from '@/model/RoleModel'
import MyTree from '@/components/MyTree/MyTree'
import authorityService from '@/services/authorityService'
import roleAuthorityService from '@/services/roleAuthorityService'

export default {
  name: 'RoleForm',
  components: {
    MyTree
  },
  props: {
    roleModel: {
      type: RoleModel,
      default () {
        return new RoleModel()
      }
    }
  },
  data () {
    return {
      authorities: [],
      props: {
        label: 'authorityName',
        children: 'children'
      },
      rules: {
        roleName: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getAuthorities () {
      authorityService.treeList()
        .then(res => {
          if (res.data.code === 1) {
            this.authorities = res.data.data
            if (!this.authorities.length) {
              this.roleModel.authoritiesGuidList = []
            } else {
              roleAuthorityService.listByRole(this.roleModel.roleGuid)
                .then(res => {
                  if (res.data.code === 1) {
                    this.roleModel.authoritiesGuidList = res.data.data.map(item => item.authorityId)
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
                .catch(err => {
                  this.$message({
                    type: 'error',
                    message: '获取数据失败！'
                  })
                  console.log(err.request.response)
                })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
    this._getAuthorities()
  }
}
</script>
