<template>
  <div class="tree">
    <el-tree ref="myTree"
      :data="nodes"
      :node-key="nodeKey"
      :props="props"
      show-checkbox
      @check-change="handleCheckChange">
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'MyTree',
  props: {
    nodes: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    nodeKey: {
      type: String,
      default: '',
      required: true
    },
    props: {
      type: Object,
      defautl () {
        return {
          label: 'name',
          children: 'children'
        }
      }
    },
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    value (newValue) {
      this._setChecked(newValue)
    }
  },
  methods: {
    handleCheckChange () {
      let checkedNodes = this.$refs.myTree.getCheckedNodes(false, true)

      this.$emit('input', checkedNodes.map(node => node[this.nodeKey].toString()))
    },
    _setChecked (value) {
      this.$refs.myTree.setCheckedKeys([])

      if (!value) {
        return
      }

      value.forEach((item) => {
        this.$refs.myTree.setChecked(item.toString(), true, false)
      })
    }
  },
  mounted () {
    this._setChecked(this.value)
  }
}
</script>

<style scoped lang="scss">
::v-deep label.el-checkbox {
  margin-right: 8px;
  padding-top: 8px;
}
</style>
