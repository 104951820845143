import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'roleauthority'
let listByUserUrl = 'GetListByRoleGuid'

class RoleAuthorityService extends BaseService {
  listByRole (roleGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listByUserUrl), {
      params: {
        roleGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const roleAuthorityService = new RoleAuthorityService(resourceName)

export default roleAuthorityService
